$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";$nav-spacing: map-get($grid, column-gap) * 1.5;
$entry-image-shadow: 25px;

.sw-history {
  align-items: center;
  display: flex;
  flex-direction: column;
  @include sw-container(false, 990px);

  > .title {
    margin-top: map-get($grid, row-gap);

    @include media('>=desktop') {
      margin-top: map-get($grid, row-gap) * 2;
    }
  }

  > .text {
    margin: map-get($grid, row-gap) / 2 0 map-get($grid, row-gap);
  }
}

.sw-history__cnt {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sw-history__nav {
  display: block;
  font-size: rem(14);
  font-weight: map-get($font-weights, semibold);
  overflow: hidden;
  order: 0;
  margin-bottom: map-get($grid, row-gap) * 2;
  text-align: center;
  width: 100%;
}

.sw-history__navLink {
  color: currentColor;
  display: inline-block;
  position: relative;
  margin-right: $nav-spacing;
  vertical-align: middle;

  &:not(:hover) {
    text-decoration: none;
  }

  &:not(:last-child)::after {
    content: '|';
    display: inline-block;
    margin-right: -$nav-spacing;
    line-height: 1;
    width: $nav-spacing;
  }
}

.sw-history__entry {
  order: 2;

  @include media('>=history') {
    align-self: flex-start;
    width: 100%;
  }
}

.sw-history__entryHeader {
  font-size: rem(18);
  font-weight: map-get($font-weights, semibold);
  margin: 0.25em 0;

  @include media('>=history') {
    text-align: center;
  }
}

.sw-history__entryList {
  list-style: none;
  margin: 0;
  padding: 0 0 0 (map-get($grid, column-gap) * 2);
  position: relative;

  @include media('>=history') {
    padding-left: 0;
    width: 100%;
  }

  &::before {
    background: map-get($borders, history);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 1px;

    @include media('>=history') {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.sw-history__entryListItem {
  display: grid;
  gap: map-get($grid, row-gap) (map-get($grid, column-gap) * 6);
  padding: map-get($grid, row-gap) 0;

  @include media('>=history') {
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto auto 1fr;
  }

  &:first-child {
    padding-top: map-get($grid, row-gap) * 2;
  }

  &:last-child {
    padding-bottom: map-get($grid, row-gap) * 2;
  }
}

.sw-history__entryListItem--reverse {
  @include media('>=history') {
    --sw-history-entry-col: 1;
    --sw-history-entry-ta: right;
  }
}

.sw-history__entryYear {
  color: var(--sw-hero-foreground);
  font-size: rem(28);
  font-weight: map-get($font-weights, normal);
  position: relative;

  @include media('>=history') {
    grid-column: calc(2 - var(--sw-history-entry-col, 0));
    grid-row: 2;
    text-align: var(--sw-history-entry-ta);
  }

  &::before {
    background: map-get($borders, history);
    content: '';
    height: 100%;
    left: map-get($grid, column-gap) * -2;
    position: absolute;
    top: 0;
    width: 4px;

    @include media('>=history') {
      left: (map-get($grid, column-gap) * -3);

      .sw-history__entryListItem--reverse & {
        left: auto;
        right: (map-get($grid, column-gap) * -3);
      }
    }
  }
}

$img-width: clamp-fallback(#{rem(230)}, 100%, #{rem(400)});
$img-height: clamp-fallback(#{rem(200)}, 100%, #{rem(348)});
$img-shift-x: clamp-fallback(#{rem(25)}, 12%, #{rem(40)});
$img-shift-y: clamp-fallback(#{rem(20)}, 10%, #{rem(36)});
$border-height: 8px;

.sw-history__entryImgShadowCnt {
  display: inline-grid;
  grid-template-columns: 1fr auto;
  padding-right: $img-shift-x;
  margin-bottom: calc(#{$img-shift-y} + #{$border-height});
  position: relative;
  width: fit-content;

  @include media('>=history') {
    grid-column: calc(1 + var(--sw-history-entry-col));
    grid-row: 1 / 5;
    justify-self: var(--sw-history-entry-col, end);
  }

  &::after {
    aspect-ratio: 1.15;
    background: map-get($backgrounds, light-bg);
    border-bottom: $border-height solid var(--sw-hero-foreground, currentColor);
    box-sizing: content-box;
    content: '';
    display: block;
    height: 100%;
    margin-left: $img-shift-x;
    left: 0;
    position: absolute;
    top: $img-shift-y;
    width: calc(100% - #{$img-shift-x});
  }
}

.sw-history__entryImg {
  aspect-ratio: 1.15;
  max-height: rem(348);
  object-fit: cover;
  object-position: center;
  position: relative;
  width: $img-width;
  z-index: map-get($z, base);
}

.sw-history__entryDesc {
  max-width: rem(500);
  font-weight: map-get($font-weights, light);

  @include media('>=history') {
    grid-column: calc(2 - var(--sw-history-entry-col, 0));
    grid-row: 3;
    text-align: var(--sw-history-entry-ta);
  }
}

.sw-history__back-to-top {
  order: 1;
  position: sticky;
  top: 100%;
  width: 100%;

  @include media('<history-lg') {
    display: none;
  }
}

.sw-history__back-to-top__link {
  display: block;
  height: rem(82);
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(100%, -100%);
  width: rem(82);

  .sw-icon {
    width: 100%;
    height: 100%;
  }
}
